/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
		  	
		  	$('[data-toggle="tooltip"]').tooltip();
		  
		  	//MOVE MENU BASED ON VIEWPORT
			function navDOMRelocation() {
				if (Modernizr.mq('only screen and (max-width: 992px)')) {
					$(".search-form").appendTo("#mobile-menu");
					$("#menu-primary-navigation").appendTo("#mobile-menu");
				}

				if (Modernizr.mq('only screen and (min-width: 992px)')) { 
					$("#menu-primary-navigation").appendTo(".primary-navigation-wrapper");
					$(".search-form").appendTo("#search-wrapper");
					$("#mobile-menu-backdrop").removeClass('visible');
					$("body").removeClass('open');
				}
			}
		  
		  	function dropdownarrows() {
            if (Modernizr.mq('only screen and (max-width: 992px)')) {
				$("a.dropdown-toggle").each( function( index, element ) {
					$( this ).append("<svg version='1.1' class='dropdown-toggle-arrow' width='10' height='8' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 40 23.8' enable-background='new 0 0 40 23.8' xml:space='preserve'> <polygon fill='#1C2C43' points='20.1,23.8 0.1,3.8 3.9,0 20.1,16.5 36.4,0 40.1,3.8 '/></svg>");
               });
            }  else {
				$(".dropdown-toggle-arrow").remove(".dropdown-toggle-arrow");
            }
        }

        dropdownarrows();  
		  
			$('.visual-content-list a').click(function(){
				$('html, body').animate({
					scrollTop: $( $.attr(this, 'href') ).offset().top - 20
				}, 500);
				return false;
			});
		  
			function equalHeight(group) {
				if (Modernizr.mq('only screen and (min-width: 767px)')) {
					group.removeAttr('style');
					var tallest = 0;
					group.each(function() {
					tallest = Math.max($(this).height(),tallest); 
				});
					group.height(tallest);
				} else {
					group.removeAttr('style');
				}
			}
		  
			function resizeMapChart() {
				if ($("#geographical_work").length) {
					drawMap();
				}
			}
		  
		  	$(function () {
				$(window).scroll(function () {
					if ($(this).scrollTop() > 100) {
						$('.scroll-to-top').fadeIn();
					} else {
						$('.scroll-to-top').fadeOut();
					}
				});

				$('.scroll-to-top').click(function () {
					$('body,html').animate({
					scrollTop: 0
					}, 400);
					return false;
				});
			});
		  
		  	resizeMapChart();
		  	navDOMRelocation();
			equalHeight($(".content-service-home-page"));
			equalHeight($(".visual-content-list a"));
			equalHeight($(".expertise .service-box h3"));

			$(window).resize(function(){
				dropdownarrows(); 
				navDOMRelocation();
				resizeMapChart();
				equalHeight($(".content-service-home-page")); 
				equalHeight($(".visual-content-list a"));
				equalHeight($(".expertise .service-box h3"));
			});
		  
			$( ".mobile-menu-toggle" ).click(function() {
				$(this).toggleClass("active");
				$("#mobile-menu-backdrop").toggleClass("visible");
				$("body").toggleClass("open");
			});
		  
			$( ".mobile-toggle-group-navigation" ).click(function() {
				$( this ).toggleClass('open');
				$( this ).parent().find(".mobile-toggle-group").toggleClass('open');
			});
		  
		  	$( "#mobile-menu-backdrop" ).click(function() {
				$(this).toggleClass("visible");
				$(".mobile-menu-toggle").toggleClass("active");
				$("body").toggleClass("open");
			});	  
		  	$('.post-gallery').slick({         
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				dots: true,
				fade: false,
				autoplay: true,
				autoplaySpeed: 7000
			});
		  
		  	$('.home-carousel').slick({         
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				fade: false,
				prevArrow: $('#home-carousel-prev'),
				nextArrow: $('#home-carousel-next'),
				autoplay: true,
				autoplaySpeed: 7000,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							arrows: false,
							centerMode: true,
							centerPadding: '40px',
							slidesToShow: 3
						}
					},
					{
						breakpoint: 768,
						settings: {
							arrows: false,
							dots: true
						}
					}
				  ]
			});
		  
			$('#loading-panel ').css('opacity','0');
			$('.home-carousel').css('opacity','1');
			$('#loading-panel ').css('display','none');
		  
		  $('.client-carousel').slick({
			infinite: true,
			slidesToShow: 5,
			autoplay: true,
			autoplaySpeed: 1500,
			slidesToScroll: 1,
			prevArrow: $('#client-carousel-prev'),
			nextArrow: $('#client-carousel-next'),
			arrows: true,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 4
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1
					}
				}
			  ]  
		  });
		  
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
		  
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
